<template>
  <v-dialog v-model="dialog" width="500px" persistent @click:outside="$emit('close')">
    <v-card>
      <v-card-title><v-icon class="mb-1 mr-2">mdi-email-fast-outline</v-icon> Enviar e-mail de teste</v-card-title>
      <v-card-text>
        <v-row justify="center">
          <v-col cols="10">
            <label class="detail-title">Destinatário para e-mail de teste</label>
            <v-text-field v-model="recipient" class="mb-0" outlined hide-details />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-container class="pa-1" fluid>
          <v-row justify="end">
            <v-btn color="primary" class="mx-2" :loading="loading" @click="send">Enviar</v-btn>
            <v-btn color="primary" class="mx-2" text :disabled="loading" @click="$emit('close')">Cancelar</v-btn>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    credentials: Object,
    domain: String,
    senderName: String
  },
  data: () => ({
    recipient: '',
    loading: false
  }),
  methods: {
    async send() {
      const url = 'https://api-sp3.infobip.com/email/2/send'
      const formData = new FormData()
      let from = this.domain
      if (this.senderName) {
        from = `${this.senderName} <${this.domain}>`
      }
      formData.append('from', from)
      formData.append('to', this.recipient)
      formData.append('subject', 'Test e-mail subject')
      formData.append('html', '<h1>Test e-mail body</h1>')
      this.loading = true
      let response
      try {
        response = await fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Basic ' + btoa(this.credentials.login + ':' + this.credentials.password)
          }
        })
      } catch (e) {
        this.$snackbar({ message: `Erro ao enviar email: ${e}`, snackbarColor: '#F44336' })
      } finally {
        this.loading = false
      }
      if (!response.ok) {
        this.$snackbar({ message: `Erro ao enviar email com status ${response.status}`, snackbarColor: '#F44336' })
      } else {
        this.$snackbar({ message: 'Email enviado com sucesso', snackbarColor: '#2E7D32' })
      }
      this.$emit('input', false)
    }
  }
}
</script>
